@import url(https://fonts.googleapis.com/css?family=Nunito Sans);
body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my_container h1 {
  color:#334B63; /* or hexidecimal */
  font-family: 'Avenir Book';
  font-size: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center {
  padding: "0";
  margin: "0";
  display: inline-block;
}
ol.center li {
  text-align: left;
}

#vizContainer {
  width: 100%;
  height: 100%;
  margin: auto;
}

.App-link {
  color: #61dafb;
}
.footer_ {
  margin-left: 18%;
  margin-right: 5%;
}

.mobileFooter_ {
  margin-left: 5%;
  margin-right: 5%;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Avenir Book";
  src: url(/static/media/Avenir-Book.16e7f491.eot);
  src: url(/static/media/Avenir-Book.c234b1f3.ttf) format("truetype"),
    url(/static/media/Avenir-Book.dc54a569.woff) format("woff");
}

@font-face {
  font-family: "Avenir Black";
  src: url(/static/media/Avenir-Black.69ddcdc8.eot);
  src: url(/static/media/Avenir-Black.05f89471.ttf) format("truetype"),
    url(/static/media/Avenir-Black.9557b515.woff) format("woff");
}

